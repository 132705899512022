import React from "react";
import TextLayout from "../layouts/TextLayout";
import SneakyFlip from "../games/SneakyFlip";

const SneakyFlipPage = () => {
  return (
      
      <TextLayout>
      <img 
        src={require("../images/sneaky-flip/icon.jpg")} 
        alt="Lucky jetter" 
        className="object-contain w-full max-w-[200px] h-auto" 
        />
            <h1 className="text-3xl font-bold mb-4">Sneaky Flip</h1> 
            <h3 className="text-xl mb-4">Tools: Unity, C#</h3>

              <p className="text-left"> 
                  <h2 className="text-2xl font-semibold mb-4 mt-4">Overview:</h2>

                  In March 2024, I developed a mobile puzzle game where players match four squares of the same kind to destroy them. I was responsible 
                  for the game logic and mechanics, following a provided game design document, while the assets, sounds, and UI were pre-supplied. The 
                  game features customizable square images and overall appearance, with two game modes: "Marathon," where players set a target score, and 
                  "Infinity," where the goal is to achieve the highest score. The controls are designed to mimic the feel of classic console controllers.
                  
                  <h2 className="text-2xl font-semibold mb-4 mt-4">Try it yourself:</h2>
              </p>
              <SneakyFlip/>

      </TextLayout>
  );
};

export default SneakyFlipPage;