import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import UniversityProjects from "./pages/UniversityProjects";
import AboutMe from "./pages/AboutMe";
import FreelanceWork from "./pages/FreelanceWork";
import Miscellaneous from "./pages/Miscellaneous";
import HilbertCurvePage from "./uni-projects/HilbertCurvePage";
import HuntersAndHidersPage from "./uni-projects/HuntersAndHidersPage";
import SeminarMaxPuredataPage from "./uni-projects/SeminarMaxPuredataPage";
import MansBestFriendPage from "./uni-projects/MansBestFriendPage";
import VRClassroomPage from "./uni-projects/VRClassroomPage";
import BubbleShooterMasterPage from "./freelance/BubbleShooterMasterPage";
import Layout from "./layouts/Layout";
import LuckyJetterPage from "./freelance/LuckyJetterPage";
import SneakyFlipPage from "./freelance/SneakyFlipPage";
import MatryoshkaTigerPage from "./freelance/MatryoshkaTigerPage";
import GateToOnePage from "./freelance/GateToOnePage";

function App() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path={`${process.env.PUBLIC_URL}/`} element={<Home />} />
          <Route
            path={`${process.env.PUBLIC_URL}/university`}
            element={<UniversityProjects />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/about-me`}
            element={<AboutMe />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/freelance`}
            element={<FreelanceWork />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/misc`}
            element={<Miscellaneous />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/hilbert-curve`}
            element={<HilbertCurvePage />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/hnh`}
            element={<HuntersAndHidersPage />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/seminar-max-puredata`}
            element={<SeminarMaxPuredataPage />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/mans-best-friend`}
            element={<MansBestFriendPage />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/vr-classroom`}
            element={<VRClassroomPage />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/bubble-shooter`}
            element={<BubbleShooterMasterPage />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/lucky-jetter`}
            element={<LuckyJetterPage />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/sneaky-flip`}
            element={<SneakyFlipPage />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/matryoshka-tiger`}
            element={<MatryoshkaTigerPage />}
          />
          <Route
            path={`${process.env.PUBLIC_URL}/gate-to-one`}
            element={<GateToOnePage />}
          />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
