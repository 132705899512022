import React from "react";

const TextLayout = ({ children }) => {
  return (
    <div className="flex flex-col items-center p-8 mx-auto max-w-2xl">
      {children}
    </div>
  );
};

export default TextLayout;