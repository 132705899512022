import React from "react";

const GitHubMark = ({ children }) => {
  return (
    <div className="flex items-center p-4 pb-8">
      <img 
        src={require("../images/github-mark.png")} 
        alt="GitHub Logo" 
        className="h-6 w-6 mr-2"
      />
      <span className="text-gray-700">{children}</span>
    </div>
  );
};

export default GitHubMark;
