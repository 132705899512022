import React, {Fragment, useEffect} from "react";
import { Unity, useUnityContext } from "react-unity-webgl";

function GateToOne()  {

    const { unityProvider, loadingProgression, isLoaded, unload } = useUnityContext({
        loaderUrl: "/games/GateToOneBuild/Build/GateToOneBuild.loader.js",
        dataUrl: "/games/GateToOneBuild/Build/GateToOneBuild.data",
        frameworkUrl: "/games/GateToOneBuild/Build/GateToOneBuild.framework.js",
        codeUrl: "/games/GateToOneBuild/Build/GateToOneBuild.wasm",
    });

    useEffect(() => {
      return () => {
        unload().catch((error) => {
          console.error("Failed to unload the Unity game:", error);
        });
      };
    }, [unload]);

    return (
        <Fragment>
          {!isLoaded && (
            <p>Loading Game... {Math.round(loadingProgression * 100)}%</p>
          )}
          <Unity matchWebGLToCanvasSize={true} style={{ width: "340px", height: "640px" }} unityProvider={unityProvider} />
        </Fragment>
      );
};

export default GateToOne;