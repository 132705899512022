import React from "react";
import Layout from "../layouts/Layout";
import { useNavigate } from "react-router-dom";
import GridLayout from "../layouts/GridLayout";
import Card from "../components/Card";

const UniversityProjects = () => {
    const navigate = useNavigate();
   
    const handleNavigate = (destination) => {
      navigate(destination);
      };
  
    return (
          <GridLayout>
          <Card 
          image={require("../images/covers/vr-classroom-cover.gif" )}
          heading="Bachelor's Thesis: VR Classroom" 
          smallText={`Take a look at my Bachelor's thesis titled "Interactive Multiplayer Classroom Design" in 
            Virtual Reality which was done in the summer semester of 2024.`}
          onClick={() => handleNavigate("/vr-classroom")} 
        />
        <Card 
          image={require("../images/covers/hnh-cover.gif")} 
          heading="Hunters and Hiders" 
          smallText={`A semester project as part of the practical course "Social Gaming" focused on mobile game 
          development and utilizing mobile and social context.`}
          onClick={() => handleNavigate("/hnh")} 
        />
        <Card 
          image={require("../images/covers/hilbert-cover.gif")}
          heading="Hilbert curve algorithm" 
          smallText={`This project was completed as part of the practical course "Aspects of Low-Level Programming 
          in Game Development" and involved developing an algorithm for a given problem using both Assembly language and C.`}
          onClick={() => handleNavigate("/hilbert-curve")} 
        />
        <Card 
          image={require("../images/covers/mbf-cover.gif")} 
          heading="Man's best friend" 
          smallText={`A prototype of a indie game idea which was done for the course "Indie games: From Begin to Market"
          in a team of five students.`}
          onClick={() => handleNavigate("/mans-best-friend")} 
        />
        <Card 
          image={require("../images/covers/seminar-cover.gif")} 
          heading="Seminar work: Max/Puredata" 
          smallText={`This paper was written for the seminar "Next-Gen Programming Interfaces and Compilers" and explores 
          the visual programming languages Max/PureData, focusing on their expressiveness and demonstrating through a 
          practical example.`}
          onClick={() => handleNavigate("/seminar-max-puredata")} 
        />
        
        
        </GridLayout>
    );
  };

export default UniversityProjects;