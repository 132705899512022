import React from "react";
import Layout from "../layouts/Layout";
import TextLayout from "../layouts/TextLayout";
import GitHubMark from "../components/GitHubMark";

const VRClassroomPage = () => {

  return (
      <TextLayout>
        <h1 className="text-3xl font-bold mb-4">Bachelor's Thesis: VR Classroom</h1> 
        <img 
                src={require("../images/vr-classroom/classroom-presentation.png")} 
                alt="Walking person animation" 
                className="object-contain w-full max-w-[500px] p-4 h-auto" 
                />
        <p>
        You can view the thesis <a href={process.env.PUBLIC_URL + "/VRClassroom/VR_Classroom_Bachelor_s_Thesis.pdf"} target="_blank" rel="noopener noreferrer" className="text-blue-500">here</a>, the demo <a href="#demo" className="text-blue-500">here</a> and
        the presentation <a href={process.env.PUBLIC_URL + "/VRClassroom/VR Classroom Presentation.pdf"} target="_blank" rel="noopener noreferrer" className="text-blue-500">here</a>.
        </p>
        <h3 className="text-xl mb-4">Tools: Unity, C#, Flask, Python, Photon Pun/Voice, LaTeX</h3>

        <p className="text-left"> 
            <h2 className="text-2xl font-semibold mb-4 mt-4">Overview:</h2>

            My Bachelor's thesis at the Technical University of Munich, conducted over a 4-month period with the Chair of Human-Centered Technologies for Learning, was titled "Interactive Multiplayer Classroom Design in
            Virtual Reality." Supervised by Süleyman Özdel, the project aimed to research and design a realistic virtual classroom that could support more than two participants and was developed using the Unity game engine.
            The user interface for the virtual classroom is a head-mounted display with either controllers or hand tracking for interaction.. The application was designed to prioritize intuitiveness and immersion, ensuring that learners from various target groups could comfortably engage in the environment. After completing the implementation, I wrote 
            the thesis, analyzing the strengths and weaknesses of the platform, detailing its features, and describing the design process. At the end of the project, I gave a 30-minute presentation to the chair, showcasing 
            the final design.

            <h2 className="text-2xl font-semibold mb-4 mt-4">Contribution:</h2>

            I have solely contributed to the successful completion of my Bachelor's thesis by doing research, managing my time, desiging, implementing and presenting the virtual classroom.

            <h2 className="text-2xl font-semibold mb-4 mt-4 " id="demo">Demo:</h2>

            <iframe
            width="100%"
            height="400"
            src="https://www.youtube.com/embed/sfdFmnyjmLM?si=nioKDa4NbGcDHWfD"
            title="Bachelor's Thesis: VR Classroom | Demo" 
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            />
        </p>

       
        </TextLayout>
  );
};

export default VRClassroomPage;