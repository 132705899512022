import React from "react";
import BubbleShooterMaster from "../games/BubbleShooterMaster";
import TextLayout from "../layouts/TextLayout";

const BubbleShooterMasterPage = () => {
  return (
      
      <TextLayout>
      <img 
        src={require("../images/bubble-shooter/logo.png")} 
        alt="Bubble shooter masters logo" 
        className="object-contain w-full max-w-[200px] h-auto" 
        />
            <h1 className="text-3xl font-bold mb-4">Bubble Shooter Masters</h1> 
            <h3 className="text-xl mb-4">Tools: Unity, C#</h3>

              <p className="text-left"> 
                  <h2 className="text-2xl font-semibold mb-4 mt-4">Overview:</h2>

                  In April 2024, I took on a freelance project to develop a WebGL Bubble Shooter game using Unity. Not only did I handle the coding for the game mechanics and logic, but I also dove into asset creation and curation to bring everything together. 
                  The game includes multiple difficulty levels and offers four unique abilities: the horizontal and diagonal eraser, a bomb, and a bubble-swap function. The challenge ends when the bubbles reach the bottom, and instead of a win condition, players 
                  are encouraged to aim for the highest score. Plus, there are handy options to adjust the sound and tweak the difficulty level. It took me about a week to complete, and the client’s feedback was bubbling with positivity. 
                  
                  
                  <h2 className="text-2xl font-semibold mb-4 mt-4">Try it yourself:</h2>
              </p>
              <BubbleShooterMaster/>

      </TextLayout>
  );
};

export default BubbleShooterMasterPage;