import React from "react";
import Layout from "../layouts/Layout";
import { useNavigate } from "react-router-dom";
import TextLayout from "../layouts/TextLayout";

const Miscellaneous = () => {
    const navigate = useNavigate();
   
    const handleNavigate = (destination) => {
      navigate(destination);
      };
  
    return (
         <TextLayout>
          <h1 className="text-2xl font-semibold mb-4">Other things I do</h1> 
          <img 
          src={require("../images/covers/under-construction.png")} 
          alt="Under construction" 
          className="object-contain w-full max-w-[950px] h-auto" 
          />
        </TextLayout>
    );
  };

export default Miscellaneous;