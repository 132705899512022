import React from "react";
import TextLayout from "../layouts/TextLayout";
import LuckyJetter from "../games/LuckyJetter";

const LuckyJetterPage = () => {
  return (
      
      <TextLayout>
      <img 
        src={require("../images/lucky-jetter/jetpack-boy.png")} 
        alt="Lucky jetter" 
        className="object-contain w-full max-w-[200px] h-auto" 
        />
            <h1 className="text-3xl font-bold mb-4">Lucky Jetter</h1> 
            <h3 className="text-xl mb-4">Tools: Unity, C#</h3>

              <p className="text-left"> 
                  <h2 className="text-2xl font-semibold mb-4 mt-4">Overview:</h2>

                  In March 2024, I was commissioned to develop a Ball Merge game for both iOS and Android, with a tight 5-day deadline. My responsibilities included coding the game logic and mechanics according to a provided game design document, 
                  however all assets, sound and sample scenes were provided to me.The game includes a daily milestones system, integrates notifications, features a checklist for unlocking different balls, and offers in-game abilities to enhance the overall experience. 
                  You lose the game if the balls overflow the container, and there’s no official win condition—players simply aim to achieve the highest score.              
                  
                  <h2 className="text-2xl font-semibold mb-4 mt-4">Try it yourself:</h2>
              </p>
              <LuckyJetter/>

      </TextLayout>
  );
};

export default LuckyJetterPage;