import React from "react";
import Layout from "../layouts/Layout";
import TextLayout from "../layouts/TextLayout";
import GitHubMark from "../components/GitHubMark";

const HuntersAndHidersPage = () => {

  return (
      <TextLayout>
        <img 
        src={require("../images/hnh/hnh-logo.png")} 
        alt="Hunters and Hiders logo" 
        className="object-contain w-full max-w-[250px] h-auto" 
        />

        <h1 className="text-3xl font-bold mb-4">Hunters and Hiders</h1> 

        <h3 className="text-xl mb-4">Tools: Unity, C#, Django, Python, Mapbox, Git, Procreate</h3>

        <p className="text-left"> 
            <h2 className="text-2xl font-semibold mb-4 mt-4">Overview:</h2>

            As part of the practical course "Social Gaming," I collaborated with four other students to create a game that uses the device’s geolocation, as well as its accelerometer and/or gyroscope sensors. 
            The game had to support more than two players and incorporate a social system. The course was divided into two phases: first, we brainstormed and developed ideas; second, we selected one concept 
            and brought it to life through full development.
            <h2 className="text-2xl font-semibold mb-4 mt-4">First phase:</h2>

            The first phase took one month and included three tasks. The first one was to personally sketch out 10 ideas that met the desired requirements, choose one, and then sketch out 10 features for it. You can 
            view them <a href="/HnH/10+10_Sketches_Pavel.pdf" target="_blank" rel="noopener noreferrer" className="text-blue-500">here</a>. The second task involved 
            selecting one idea from the 50 team ideas and developing 10 features for it, which you can view <a href="/HnH/Team_Idea.pdf" target="_blank" rel="noopener noreferrer" 
            className="text-blue-500">here</a>. The third task was to create a storyboard, which can be found <a href="/HnH/Storyboard.pdf" target="_blank" rel="noopener noreferrer" 
            className="text-blue-500">here</a>, followed by creating a 3-minute video for the idea presentation.

            <h2 className="text-2xl font-semibold mb-4 mt-4">Second phase:</h2>
            
            In the second phase, we fully developed the chosen idea, a twist on the classic game of Hide-and-Seek, utilizing the GPS, accelerometer, and gyroscope data from players' phones. The game involves two roles: 
            hunters, who win by catching all hiders, and hiders, who win if they avoid being caught until the timer runs out. Both roles can unlock and select two in-game abilities before joining a match. Hunters receive 
            periodic updates on the general location of hiders, and when a hider enters a hunter's proximity, the hunter gets an alert. The hunter can then perform a specific gesture with their phone to catch the hider, 
            who is notified and removed from the game upon capture.

            Examples of abilities include Farsight, Rangefinder, and Track Reading.
            <div className="flex justify-center space-x-4">
            <img 
                src={require("../images/hnh/farsight.gif")} 
                alt="Farsight ability" 
                className="w-1/2 h-auto max-w-[200px] object-cover" 
            />
            <img 
                src={require("../images/hnh/rangefinder.gif")} 
                alt="Rangefinder ability" 
                className="w-1/2 h-auto max-w-[200px] object-cover" 
            />
            <img 
                src={require("../images/hnh/trackreading.gif")} 
                alt="Track Reading ability" 
                className="w-1/2 h-auto max-w-[200px] object-cover" 
            />
            </div>

            The application also features a authorization system, friendship system, creating and joining a match. 


            <h2 className="text-2xl font-semibold mb-4 mt-4">Contribution:</h2>

            I contributed to both the design and implementation of our game idea. All of the sketches linked on this page were created by me using Procreate. During the development phase, I was solely responsible for the backend, 
            which was implemented using Django with Python. For the frontend, I worked alongside another team member, using Unity C#. We also integrated the Mapbox framework as our map provider and used Git for version control.

            <h2 className="text-2xl font-semibold mb-4 mt-4">Live Demo:</h2>

            <iframe
            width="100%"
            height="400"
            src="https://www.youtube.com/embed/ds5XEt2ZJcE"
            title="Hunters and Hiders | Live Demo" 
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            />
        </p>

       
        </TextLayout>
  );
};

export default HuntersAndHidersPage;