import React from "react";
import TextLayout from "../layouts/TextLayout";
import LuckyJetter from "../games/LuckyJetter";
import MatryoshkaTiger from "../games/MatryoshkaTiger";

const MatryoshkaTigerPage = () => {
  return (
      
      <TextLayout>
      <img 
        src={require("../images/matryoshka-tiger/icon.jpg")} 
        alt="Lucky jetter" 
        className="object-contain w-full max-w-[200px] h-auto" 
        />
            <h1 className="text-3xl font-bold mb-4">Matryoshka Tiger</h1> 
            <h3 className="text-xl mb-4">Tools: Unity, C#</h3>

              <p className="text-left"> 
                  <h2 className="text-2xl font-semibold mb-4 mt-4">Overview:</h2>

                  In April 2024, I created a mobile game where players slide matryoshkas across a playfield, aiming to land them on various markers that 
                  award points. Players can use in-game currency to purchase six different abilities from the shop scene. Additionally, the game features 
                  a daily mini-game that rewards points upon successful completion. I handled the coding of the game logic and mechanics, while the UI design, 
                  assets, and sound were provided to me.

                  <h2 className="text-2xl font-semibold mb-4 mt-4">Try it yourself:</h2>
              </p>
              <MatryoshkaTiger/>

      </TextLayout>
  );
};

export default MatryoshkaTigerPage;