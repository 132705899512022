import React from "react";
import Layout from "../layouts/Layout";
import TextLayout from "../layouts/TextLayout";
import GitHubMark from "../components/GitHubMark";

const SeminarMaxPuredataPage = () => {

  return (
      <TextLayout>
        <h1 className="text-3xl font-bold mb-4">Seminar Paper: Max/Puredata</h1> 
       
        <p>
        You can view the seminar paper <a href={process.env.PUBLIC_URL + "/Seminar/MaxPuredata.pdf"} target="_blank" rel="noopener noreferrer" className="text-blue-500">here</a> and the presentation <a href={process.env.PUBLIC_URL + "/Seminar/Max_PureData Presentation.pdf"} target="_blank" rel="noopener noreferrer" className="text-blue-500">here</a>.
        </p>

        <h3 className="text-xl mb-4">Tools: Max, Puredata, LaTeX</h3>

        <img 
        src={require("../images/seminar/practical-example.png")} 
        alt="Max practical example" 
        className="object-contain w-full max-w-[700px] p-4 h-auto" 
        />

        <p className="text-left"> 
            <h2 className="text-2xl font-semibold mb-4 mt-4">Overview:</h2>

            During my Bachelor's studies at the Technical University of Munich, I wrote a paper for the seminar "Next-Gen Programming Interfaces and Compilers" on the topic of visual programming languages, 
            specifically Max and PureData, which are widely used for sound design and music composition. The paper, limited to 10 pages, explored the expressiveness of these languages and their practical applications. 
            At the end of the course, I delivered a 20-minute presentation to my peers, showcasing the features and uses of these languages.

            <h2 className="text-2xl font-semibold mb-4 mt-4">Contribution:</h2>

            My contribution to this project involved researching the topic and composing a 10-page paper using LaTeX. Additionally, I prepared and delivered a presentation on my findings to both professors and peers.

        </p>

        </TextLayout>
  );
};

export default SeminarMaxPuredataPage;