import React from "react";
import TextLayout from "../layouts/TextLayout";
import GateToOne from "../games/GateToOne";

const GateToOnePage = () => {
  return (
      
      <TextLayout>
      <img 
        src={require("../images/gate-to-one/icon.jpg")} 
        alt="Gate to one logo" 
        className="object-contain w-full max-w-[200px] h-auto" 
        />
            <h1 className="text-3xl font-bold mb-4">Gate To One</h1> 
            <h3 className="text-xl mb-4">Tools: Unity, C#</h3>

              <p className="text-left"> 
                  <h2 className="text-2xl font-semibold mb-4 mt-4">Overview:</h2>

                  Gate To One is a simple mobile and web game that blends the spirit of Whac-A-Mole with a sports theme. The goal is to score by hitting shifting targets, 
                  earning points that can be used to unlock new appearances or boosters. I was responsible for coding the game’s logic and mechanics, while the assets, sounds, 
                  and UI were supplied to me. 
                  
                  <h2 className="text-2xl font-semibold mb-4 mt-4">Try it yourself:</h2>
              </p>
              <GateToOne/>

      </TextLayout>
  );
};

export default GateToOnePage;