import React from "react";
import NavMenu from "../components/NavMenu";
import { useState } from "react";

const Layout = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="flex h-screen relative">

       {/* Mobile Header */}
       <div className="md:hidden fixed top-0 left-0 right-0 flex justify-between items-center p-4 bg-white text-black z-50">
        <img 
          src={require("../images/me.png")} 
          alt="Simply me" 
          className="object-contain w-full max-w-[50px] h-auto rounded-xl" 
          />
        <button onClick={toggleMenu} className=" text-xl focus:outline-none">
          Menu
        </button>
      </div>

      {/* NavMenu for Desktop */}
      <div className="hidden md:block w-3/12  pt-24">
        <div className="flex-grow flex items-left justify-left p-6">
        <img 
        src={require("../images/me.png")} 
        alt="Simply me" 
        className="object-contain w-full max-w-[175px] h-auto rounded-xl" 
        />
        </div>
        <NavMenu />
      </div>

      {/* NavMenu for Mobile */}
      {isMenuOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 z-30">
          <div className="fixed left-0 top-16 h-full w-64 bg-white transition-transform duration-300 transform translate-x-0 z-40">
            <NavMenu onMenuItemClick={toggleMenu} />
          </div>
        </div>
      )}

      {/* Content Area */}
      <div className={`w-full md:w-9/12 pt-20 md:pt-4 overflow-y-auto transition-all duration-300 opacity-100`}>
        {children}
      </div>
    </div>
  );
};

export default Layout;
