import React from "react";

const GridLayout = ({ children }) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-6 p-4">
      {children}
    </div>
  );
};

export default GridLayout;