import React, { useState } from "react";

const GameCard = ({ image, heading, smallText, onClick }) => {

    const [isLoading, setIsLoading] = useState(true);

    const handleImageLoad = () => {
      setIsLoading(false);
    };
    
  return (
    <div 
      className="border border-gray-300 rounded-md shadow-md overflow-hidden cursor-pointer"  
      onClick={onClick}
    >
      {image && (
        <div className="relative">
          {isLoading && (
            <div className="absolute inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-20">
              <span className="text-white text-lg">Loading...</span>
            </div>
          )}
          <img 
            src={image} 
            alt={heading} 
            className={`w-full h-auto aspect-[16/9] object-cover ${isLoading ? 'opacity-0' : 'opacity-100'}`} // Maintain aspect ratio
            onLoad={handleImageLoad}
          />
          <div className="absolute bottom-1 right-1 bg-gray-600 border border-gray-800 text-white rounded-md px-1 py-0.5 text-xs z-10">
            Playable in browser
          </div>
        </div>
      )}
      <div className="p-4">
        <h3 className="text-lg font-semibold mb-2">{heading}</h3>
        <p className="text-gray-600 text-sm">{smallText}</p>
      </div>
    </div>
  );
};

export default GameCard;
