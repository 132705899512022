import React from "react";
import Layout from "../layouts/Layout";
import TextLayout from "../layouts/TextLayout";
import GitHubMark from "../components/GitHubMark";

const MansBestFriendPage = () => {

  return (
      <TextLayout>
        <img 
        src={require("../images/mbf/MBF-logo.png")} 
        alt="Man's best friend logo" 
        className="object-contain w-full max-w-[650px] h-auto" 
        />
        <p>
        You can view the presentation of the indie game design <a href={process.env.PUBLIC_URL + "/MBF/Man's best friend.pdf"} target="_blank" rel="noopener noreferrer" className="text-blue-500">here</a> and the trailer <a href="#trailer" className="text-blue-500">here</a>.
        </p>

        <h3 className="text-xl mb-4">Tools: Unity, Procreate, Video editing software</h3>

        <p className="text-left"> 
            <h2 className="text-2xl font-semibold mb-4 mt-4">Overview:</h2>

            In the course "Indie Games: From Begin to Market," I collaborated with three other students to simulate an indie game studio and develop a prototype of our first game concept. We were responsible for delivering a game design document 
            outlining the high-level concept, a playable prototype, and a trailer. Additionally, we managed time, conducted cost analysis, and planned for the game's publishing and marketing strategies.

            <h2 className="text-2xl font-semibold mb-4 mt-4">What's "Man's Best Friend"?</h2>

            "Man's Best Friend" is a 2D pixel video game that puts you in the paws of a guide dog, following the unfolding of its story. Your choices, whether big or small, influence the narrative. The game centers around an elderly blind man,
            struggling with loneliness, whose attitude toward the world shifts as he grows more bitter. As the dog, you face a tough decision: do you stay and try to help, or do you leave when you're no longer appreciated? But what can a guide 
            dog truly do? The game uses different mechanics to enhance the story-driven experience and immerse players in the role of the guide dog.

            <h2 className="text-2xl font-semibold mb-4 mt-4">Contribution:</h2>

            I was actively involved in the design and brainstorming sessions for our game concept, and my primary responsibilities included creating the game's art, producing the trailer, and contributing to the implementation of the prototype.

            <img 
            src={require("../images/mbf/MBF-hospital-scene.png")} 
            alt="Man's best friend logo" 
            className="object-contain w-full max-w-[650px] mt-4 h-auto" 
            />
            <div className="flex justify-center items-center space-x-4">
                <img 
                src={require("../images/mbf/walking-dog.gif")} 
                alt="Walking dog animation" 
                className="object-contain w-full max-w-[300px] h-auto" 
                />

                <img 
                src={require("../images/mbf/walking-person.gif")} 
                alt="Walking person animation" 
                className="object-contain w-full max-w-[150px] h-auto" 
                />
            </div>

            <h2 id="trailer" className="text-2xl font-semibold mb-4 mt-4">Trailer:</h2>

            <iframe
            width="100%"
            height="330"
            src="https://www.youtube.com/embed/sI4i3JLS0e0?si=a-k5w-oRggH4ugfA"
            title="Man's best friend | Trailer" 
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            />

        </p>


        </TextLayout>
  );
};

export default MansBestFriendPage;