import React, {Fragment, useEffect} from "react";
import { Unity, useUnityContext } from "react-unity-webgl";

function BubbleShooterMaster()  {

    const { unityProvider, loadingProgression, isLoaded, unload } = useUnityContext({
        loaderUrl: "/games/BubbleShooterWebGlBuild/Build/BubbleShooterWebGlBuild.loader.js",
        dataUrl: "/games/BubbleShooterWebGlBuild/Build/BubbleShooterWebGlBuild.data",
        frameworkUrl: "/games/BubbleShooterWebGlBuild/Build/BubbleShooterWebGlBuild.framework.js",
        codeUrl: "/games/BubbleShooterWebGlBuild/Build/BubbleShooterWebGlBuild.wasm",
    });

    useEffect(() => {
      return () => {
        unload().catch((error) => {
          console.error("Failed to unload the Unity game:", error);
        });
      };
    }, [unload]);
    
    return (
        <Fragment>
          {!isLoaded && (
            <p>Loading Game... {Math.round(loadingProgression * 100)}%</p>
          )}
          <Unity matchWebGLToCanvasSize={true} style={{ width: "340px", height: "640px" }} unityProvider={unityProvider} />
        </Fragment>
      );
};

export default BubbleShooterMaster;