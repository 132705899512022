import React from "react";
import { NavLink, useLocation } from "react-router-dom";

const NavMenu = ({ onMenuItemClick }) => {
  const location = useLocation(); 

  const menuItems = [
    { name: "Home", route: "/" },
    { name: "University", route: "/university", activeRoutes: ["/hilbert-curve", "/hnh", "/seminar-max-puredata", "/mans-best-friend", "/vr-classroom"] },
    { name: "Freelance", route: "/freelance", activeRoutes: ["/bubble-shooter", "/lucky-jetter", "/sneaky-flip", "/matryoshka-tiger", "/gate-to-one"] },
    { name: "Misc", route: "/misc" },
    { name: "About Me", route: "/about-me" },
  ];

  const isUniversityActive = () => {
    const universityRoutes = menuItems[1].activeRoutes;
    return universityRoutes.some(route => location.pathname === route);
  };

  const isFreelanceActive = () => {
    const freelanceRoutes = menuItems[2].activeRoutes;
    return freelanceRoutes.some(route => location.pathname === route);
  };

  return (
    <nav className="text-black p-6">
      <ul>
        {menuItems.map((item, index) => (
          <li 
            className="mb-4 border-r text-xl border-black" 
            key={index}
          >
            <NavLink
              to={item.route}
              className={({ isActive }) => 
                (item.name === "University" && (isUniversityActive() || isActive)) 
                  ? "text-yellow-400 text-3xl font-bold" 
                  : (item.name === "Freelance" && (isFreelanceActive() || isActive))
                    ? "text-yellow-400 text-3xl font-bold"
                    : isActive 
                      ? "text-yellow-400 text-3xl font-bold"
                      : ""
              }
              onClick={onMenuItemClick}
            >
              {item.name}
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default NavMenu;
