import React from "react";
import Layout from "../layouts/Layout";
import TextLayout from "../layouts/TextLayout";
import GitHubMark from "../components/GitHubMark";

const HilbertCurvePage = () => {

  return (
      <TextLayout>
        <h1 className="text-3xl font-bold mb-4">Hilbert Curve Algorithm</h1> 
        
        <GitHubMark>
            <a href="https://github.com/pavelbaltov/hilbert-curve-algorithm" target="_blank" rel="noopener noreferrer" className="text-blue-500">Link to the GitHub Repository!</a>
        </GitHubMark>

        <p>
        You can view the German presentation <a href={process.env.PUBLIC_URL + "/hilbert/Vortrag.pdf"} target="_blank" rel="noopener noreferrer" className="text-blue-500">here</a>.
        </p>

        <h3 className="text-xl mb-4">Tools: ARM, C, Git, LaTeX</h3>


        <p className="text-left"> 
            <h2 className="text-2xl font-semibold mb-4 mt-4">Overview:</h2>

            During my Bachelor studies at the Technical University of Munich I was enrolled at the practical course Aspects of Low-Level Programming in Game Development
            where I had to develop an algorithm which generates the points of a n-Hilbert curve using an Assembly language. The requirements of the task included one main implementation
            in ARM and others in ARM and C used for comparison as well as a 10-page document on the topic and a 15-minute presentation.

            <h2 className="text-2xl font-semibold mb-4 mt-4">What's a Hilbert curve?</h2>

                According to Wikipedia:
                <p className="mb-4">
                    <em>"The Hilbert curve is a continuous fractal space-filling curve that maps a one-dimensional interval to a two-dimensional space. It was first described by David Hilbert in 1891. 
                        The curve is constructed through a recursive process, where each iteration increases the complexity of the curve and its ability to fill space more densely. The Hilbert curve has 
                        applications in various fields, including computer graphics, data visualization, and optimization, due to its properties of locality, which preserve spatial relationships between
                        points in a way that is beneficial for indexing and searching multidimensional data."</em>
                </p>

            <img src={require("../images/hilbert-curve-generation.gif")} alt={"Generation of a Hilbert curve"} className="w-full h-74 object-cover" />
            
            <h2 className="text-2xl font-semibold mb-4 mt-4">Contribution:</h2>

            My contribution to this project is coding three algorithms (2 in ARM and 1 in C), preparing the documentation and carrying out the presentation in front of the examiners.
            
            

        </p>


       
        </TextLayout>
  );
};

export default HilbertCurvePage;