import React from "react";
import Layout from "../layouts/Layout";
import TextLayout from "../layouts/TextLayout";

const AboutMe = () => {

  return (
      <TextLayout>
        <h1 className="text-2xl font-semibold mb-4">About me</h1> 
        <p className="text-left"> {/* Left-align text */}
          Hey! Thanks for stopping by my portfolio website. I hope you’re enjoying your visit as much as I’ve enjoyed revisiting my past work and coding this site. Did any of my 
          games catch your interest? 
          <br/>
          <br/>

          As for me, I’m Pavel, nice to meet you. I'm originally from Plovdiv, Bulgaria, where I graduated high school with top marks and focused on studying English and German. 
          I then moved to Munich, Germany, to pursue my Bachelor's degree in Informatics: Games Engineering at the Technical University of Munich. After three years of hard work, 
          I successfully completed my studies and am now ready to dive into the IT and gaming industry.
          <br/>
          <br/>

          I would describe myself as a calm and cooperative person who respects others' boundaries and strives to include everyone. I have a passion for learning and am eager to 
          take on new challenges to grow and improve.
          <br/>
          <br/>

          If you're intrigued by my work and would like to get in touch, feel free to reach out using the link - <a className="text-xl text-blue-500" href={`mailto:pavel.baltov@gmail.com`}>
          email me
          </a>
        </p>

        <h2 className="text-2xl font-semibold mb-4">Socials</h2> 

        <a 
          className="text-xl text-blue-500" 
          href="https://www.linkedin.com/in/pavelbaltov/" 
          target="_blank" 
          rel="noopener noreferrer"
        >
          My LinkedIn
        </a>
        <a 
          className="text-xl text-blue-500" 
          href="https://www.upwork.com/freelancers/~01ac401484fd71d493?mp_source=share" 
          target="_blank" 
          rel="noopener noreferrer"
        >
          My Upwork
        </a>
        <a 
          className="text-xl text-blue-500" 
          href="https://www.instagram.com/pavelbaltov/" 
          target="_blank" 
          rel="noopener noreferrer"
        >
          My Instagram
        </a>
        <a 
          className="text-xl text-blue-500" 
          href="../me/Pavel's CV.pdf" 
          target="_blank" 
          rel="noopener noreferrer"
        >
          My Curriculum vitae
        </a>





        </TextLayout>
  );
};

export default AboutMe;