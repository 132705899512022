import React from "react";
import { useNavigate } from "react-router-dom";
import GridLayout from "../layouts/GridLayout";
import GameCard from "../components/GameCard";

const FreelanceWork = () => {
    const navigate = useNavigate();
   
    const handleNavigate = (destination) => {
      navigate(destination);
      };
  
    return (
      <GridLayout>
        <GameCard 
          image={require("../images/covers/bubble-shooter-cover.gif")}
          heading="Bubble Shooter Masters" 
          smallText="In just one week, I created a WebGL Bubble Shooter game for a freelance project, packed with four distinct 
          abilities and three challenging difficulty levels. Who knew that transforming a simple bubble-popping pastime could turn into a 
          competitive adventure? It’s like bubble therapy, but with a dash of strategy!" 
          onClick={() => handleNavigate("/bubble-shooter")} 
        />
        <GameCard 
          image={require("../images/covers/lucky-jetter-cover.gif")}
          heading="Lucky Jetter" 
          smallText="A Ball Merge game targeting iOS and Android devices, this freelance project was completed in just 5 days. It includes ball-merging mechanics, a checklist to track achieved balls, notifications integration, 
          two distinct abilities, and a daily milestones system to keep you coming back." 
          onClick={() => handleNavigate("/lucky-jetter")} 
        />
        <GameCard 
          image={require("../images/covers/sneaky-flip-cover.gif")}
          heading="Sneaky Flip" 
          smallText="A mobile puzzle game where you match squares of the same kind to destroy them and earn points. The game offers customization options for the squares and overall appearance, 
          along with two distinct game modes to choose from. Just remember, while you’re busy matching squares, your procrastination meter might be going off the charts!" 
          onClick={() => handleNavigate("/sneaky-flip")} 
        />
        <GameCard 
          image={require("../images/covers/matryoshka-tiger-cover.gif")}
          heading="Matryoshka Tiger" 
          smallText="A mobile game where you slide matryoshkas across a playfield, trying to land them on various targets that award points. Use those points to purchase exciting abilities and rack 
          up even more points. Plus, there’s a daily mini-game that gives you extra points—because who doesn’t want to earn rewards for playing games within games? It’s like gaming inception!" 
          onClick={() => handleNavigate("/matryoshka-tiger")} 
        />
        <GameCard 
          image={require("../images/covers/gate-to-one-cover.gif")}
          heading="Gate To One" 
          smallText="A Whac-A-Mole-inspired game where your mission is to score goals on moving targets, earning points along the way. You can use your hard-earned in-game currency to snag boosters 
          and unlock cool new themes." 
          onClick={() => handleNavigate("/gate-to-one")} 
        />
        </GridLayout>
    );
  };

export default FreelanceWork;