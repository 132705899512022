import React from "react";
import Layout from "../layouts/Layout";
import Card from "../components/Card";
import { useNavigate } from "react-router-dom";
import GridLayout from "../layouts/GridLayout";


const Home = () => {

  const navigate = useNavigate();
   
  const handleNavigate = (destination) => {
    navigate(destination);
    };

  return (
     <GridLayout>
      <Card 
        image={require("../images/covers/uni-projects-cover.png")} 
        heading="My University projects" 
        smallText="Check out some of the masterpieces I cranked out during my time in the glamorous world of Informatics: Games Engineering 
        at the Technical University of Munich — because who wouldn’t want to see what happens when sleep deprivation meets creativity?" 
        onClick={() => handleNavigate("/university")} 
      />
      <Card 
        image={require("../images/covers/freelance-cover.png")} 
        heading="My Freelance work" 
        smallText="To expand my portfolio, I took on freelance projects specializing in game development with Unity C#. All of the games are playable 
        directly in the browser, so you can try them out yourself — just don’t blame me if you get hooked!"
        onClick={() => handleNavigate("/freelance")} 
      />
      <Card 
        image={require("../images/covers/under-construction.png")} 
        heading="Misc" 
        smallText="Not ready yet..." 
        onClick={() => {handleNavigate("/misc")}} 
      />
      <Card 
        image={require("../images/covers/about-me-cover.png")} 
        heading="About me" 
        smallText="Hey, I'm Pavel! I hope you're enjoying my portfolio. Feel free to get to know me better here! See you around! ❤️" 
        onClick={() => {handleNavigate("/about-me")}} 
      />
      </GridLayout>
  );
};

export default Home;